//
//
//
//
//
//

import RenderComponent from '~/components/wrapper/RenderComponent/RenderComponent'
import {GlobalMixin} from '~/mixin/GlobalMixin'
import Wrapper from '~/components/wrapper/wrapper'

export default {
    components: {
        Wrapper,
        RenderComponent
    },
    mixins: [
        GlobalMixin
    ],
    data() {
        return {
            vsSection: 'vs-section'
        }
    },
    head() {
        return {
            htmlAttrs: {
                lang: this.getCurrentLanguage
            },
            meta: [{
                name: 'google-site-verification',
                content: 'mEy7NZAzMyl1C-yCRO-ClKuXkxQomOYFtgg1gNHgSQc'
            }]
        }
    }
}
